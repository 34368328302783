import { useLocation, useNavigate } from "react-router-dom";
import { exhibitionFilterStore } from "./useExhibitionFilters";
import { useQuery } from "@apollo/client";
import { FETCH_EXHIBITION } from "../../../graphql/query/Exhibition";
import { Breadcrumb, Button, MenuButton, Modal } from "../../../components/ui";
import NewExhibition from "./Actions/NewExhibition";
import ExhibitionSearch from "./ExhibitionSearch";
import routes from "../pages/Wizard/routes";
import DuplicateExhibition from "./Actions/DuplicateExhibition";
import CopyDescription from "./Actions/CopyDescription";
import useUser from "../../../hooks/useUser";
import ConsignWork from "./Actions/ConsignWork";
import NewJob from "../../Jobs/components/NewJob";
import AddShipment from "../../Shipment/components/AddShipment";
import ExportActiveReport from "../../../components/Modals/ExportActiveReport";
import exportModule from "./Actions/ExportModule";
import AdhocReportBody from "./Actions/AdhocReportBody";
import { useState } from "react";

const PrimaryNav = props => {
  const { formik } = props;
  const search = exhibitionFilterStore.get();
  const location = useLocation();
  const navigate = useNavigate();
  const pathnames = location?.pathname.split("/");
  const id = pathnames?.length > 2 ? pathnames[2] : null;
  const { hasPermission } = useUser();

  const exhibitionObj = {
    contact: {
      contactAddress: {},
      contactArtist: {},
      contactFocuses: {},
      contactCompany: {},
      documents: {},
      exhUser: {},
      purchases: {},
      firstUser: {},
      type: {},
      type2: {},
    },
    exhibitionWorks: {
      artistNames: {},
      instance: {
        company: {},
        offer: {
          contact: {},
          hold: {},
          status: {},
        },
      },
    },
  };
  const { data: { exhibition } = {} } = useQuery(
    FETCH_EXHIBITION(exhibitionObj),
    {
      skip: !id,
      variables: { id: +id },
    },
  );
  const path = pathnames[pathnames?.length - 1];
  const route = routes?.find(item => item?.href?.includes(path));
  const queryParams = new URLSearchParams(window.location.search);
  const status = queryParams.get("status") || "all";
  const listId = +(
    queryParams.get("listId") ||
    (status === "selectListWorking" ? queryParams.get("workingListId") : null)
  );
  const selected = Object.keys(formik?.values?.selected?.ids || {});
  const ids = selected?.map(key => {
    const obj = formik?.values?.selected?.ids?.[key];
    return obj?.item?.id || obj?.id;
  });
  const select = formik?.values?.selected?.select;

  const statusMap = {
    all: "All Exhibitions",
    searched: "Searched Exhibitions",
    selectListWorking: "Select List (Working)",
  };
  const label = statusMap[status] || "All Exhibitions";
  const steps = [
    { label, onClick: () => navigate("/exhibition") },
    ...(exhibition
      ? [
          {
            label: exhibition?.title,
            onClick: () => navigate(`${exhibition?.id}/details`),
          },
          {
            label: route?.name,
            onClick: () => navigate(`${exhibition?.id}/${route?.href}`),
          },
        ]
      : []),
  ];

  const searchModalProps = {
    body: ExhibitionSearch,
    closeOnBackdrop: true,
    scale: "md",
  };

  const defaultActionProps = {
    closeOnBackdrop: false,
    hideCloseButton: true,
    ids: ids.length ? ids : [+id],
    listId,
    onClose: () => {
      formik?.resetForm();
    },
    scale: "md",
    search,
    select,
  };

  const [isExportReportOpen, setIsExportReportOpen] = useState(false);
  const actionMenuProps = {
    label: "Actions",
    options: [
      {
        label: "New Exhibition",
        disabled: !hasPermission("UPDATE_EXHIBITION"),
        modalProps: {
          body: NewExhibition,
          disabled: !hasPermission("UPDATE_EXHIBITION"),
          ...defaultActionProps,
        },
      },
      {
        label: "Export Exhibition Report",
        modalProps: {
          body: ExportActiveReport,
          obj: exhibitionObj,
          type: "Exhibition",
          ids: exhibition?.id ? [exhibition?.id] : ids,
          ...defaultActionProps,
          isExportReportOpen,
          onOpen: () => {
            setIsExportReportOpen(true);
          },
          onClose: () => {
            setIsExportReportOpen(false);
          },
        },
      },
      {
        disabled: !ids.length && !id,
        label: "Create an Adhoc Report",
        modalProps: {
          body: AdhocReportBody,
          ...defaultActionProps,
          exportModule,
        },
      },
      ...(id
        ? [
            {
              label: "Duplicate Exhibition",
              modalProps: {
                body: DuplicateExhibition,
                closeOnBackdrop: true,
                exhibition,
                hideCloseButton: true,
                scale: "sm",
              },
            },
            {
              label: "Copy Description",
              modalProps: {
                title: "Copy description?",
                description:
                  "Do you wish to copy the description from the exhibition?",
                scale: "sm",
                body: CopyDescription,
                closeOnBackdrop: true,
                exhibitionId: exhibition?.id,
                hideCloseButton: true,
              },
            },
            {
              label: "Consign Work",
              disabled: exhibition?.exhibitionWorks?.length < 1,
              modalProps: {
                scale: "md",
                body: ConsignWork,
                closeOnBackdrop: true,
                hideCloseButton: true,
                exhibition: exhibition,
              },
            },
            {
              label: "Loan Work",
              disabled: exhibition?.exhibitionWorks?.length < 1,
              modalProps: {
                scale: "md",
                body: ConsignWork,
                closeOnBackdrop: true,
                hideCloseButton: true,
                exhibition: exhibition,
                typeId: 2,
              },
            },
            {
              label: "Ship Work",
              disabled: exhibition?.exhibitionWorks?.length < 1,
              modalProps: {
                scale: "md",
                body: AddShipment,
                closeOnBackdrop: true,
                hideCloseButton: true,
                exhibition: exhibition,
              },
            },
            {
              disabled:
                !hasPermission("CREATE_JOB") ||
                exhibition?.exhibitionWorks?.length < 1,
              label: "Create Job",
              modalProps: {
                body: NewJob,
                scale: "md",
                exhibitionId: id,
                closeOnBackdrop: false,
                hideCloseButton: true,
              },
            },
          ]
        : []),
    ],
  };

  const breadcrumbProps = {
    currentStepIndex: steps?.length - 1,
    onChange: index => steps[index]?.onClick(),
    steps: steps?.map(item => item?.label),
  };

  return (
    <div className="flex items-center justify-between border-b px-8 py-5">
      <Breadcrumb {...breadcrumbProps} />
      <div className="flex justify-end gap-4">
        <Modal {...searchModalProps}>
          <Button label="Exhibition Search" action="black" />
        </Modal>
        <MenuButton {...actionMenuProps} />
      </div>
    </div>
  );
};

export default PrimaryNav;
