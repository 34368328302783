import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";

import { GridInputs, Table } from "../../../../../../components/Functional";
import { SectionHeader } from "../../../../../../components/ui/Header";

import { FETCH_JOB_STATUSES } from "../../../../../../graphql/query/JobStatus";
import { FETCH_AUTH_GROUPS } from "../../../../../../graphql/query/AuthGroup";
import { FETCH_JOB_TYPES } from "../../../../../../graphql/query/JobType";
import { FETCH_ENTITIES } from "../../../../../../graphql/query/Entity";
import { FETCH_TECHNICALS as fetchTechnicals } from "../../../../../../graphql/query/Technical";
import { FETCH_VIEWING_ROOM } from "../../../../../../graphql/query/ViewingRoom";
import { FETCH_JOB_ITEMS_V2 as fetchJobItemsV2 } from "../../../../../../graphql/query/JobItem";
import AddWork from "./components/Addwork";
import Actions from "./components/Actions";

const Detail = props => {
  const { jobId, formik } = props;

  const { data: { jobTypes = [] } = {} } = useQuery(FETCH_JOB_TYPES);
  const { data: { jobStatuses = [] } = {} } = useQuery(FETCH_JOB_STATUSES, {
    variables: { input: { id: { operator: "in", value: [1, 2, 3, 4, 5] } } },
  });
  const { data: { authGroups = [] } = {} } = useQuery(FETCH_AUTH_GROUPS);
  const { data: { entities } = {} } = useQuery(FETCH_ENTITIES, {
    variables: { input: { user: -1, order: ["name"] } },
  });
  const { data: { entities: pickupGroups } = {} } = useQuery(FETCH_ENTITIES, {
    variables: { input: { hide: 0, user: 0, order: ["name"] } },
  });
  const { data: { technicals } = {} } = useQuery(fetchTechnicals(), {
    variables: { input: { statusId: { operator: "notIn", value: [3, 4] } } },
  });
  const { data: { viewingRooms = [] } = {} } = useQuery(FETCH_VIEWING_ROOM, {
    variables: {
      input: {
        show: "all", // Removes inactive filter
      },
    },
  });

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "jobItemsV2",
    headers: [
      {
        rowClass: "w-[74px] pr-0",
        label: "",
        name: "image",
        type: "custom",
        component: props => (
          <img
            src={props?.values?.instance?.edition?.artwork?.imagesSummary?.imgT}
            className="h-[50px] w-[50px] bg-neutral-200"
            style={{ objectFit: "cover" }}
            alt={props?.values?.instance?.edition?.artwork?.title}
          />
        ),
      },
      {
        label: "Ref",
        name: "instance.name",
        type: "custom",
        component: ({ values }) => (
          <Link
            className="underline"
            to={`/instance/${values?.instance?.id}/details`}
          >
            {values?.instance?.name}
          </Link>
        ),
      },
      {
        label: "Artist",
        name: "artists",
        type: "custom",
        component: props => (
          <div
            className={`overflow-hidden text-ellipsis text-base font-medium leading-5 text-black`}
            style={{ maxWidth: 200 }}
          >
            {(
              props?.values?.instance?.edition?.artwork?.artists?.map(
                item => item?.name,
              ) || []
            )?.join(", ")}
          </div>
        ),
      },
      { label: "Title", name: "instance.edition.artwork.title", type: "label" },
      { label: "Year", name: "instance.edition.year", type: "label" },
      { label: "Edition", name: "instance.editionNumber", type: "label" },
      {
        label: "",
        name: "action",
        type: "custom",
        component: props => {
          return (
            <Actions
              disabled={
                formik?.values?.statusId !== 1 &&
                formik?.values?.statusId !== null
              }
              id={props?.id}
            />
          );
        },
      },
    ],
    inputs: [],
    FETCH_QUERY: fetchJobItemsV2({
      instance: {
        condition: {
          status: {},
        },
        edition: { artwork: { artists: {}, imagesSummary: { imgT: {} } } },
        status: {},
        warehouseItem: {},
      },
    }),

    variables: {
      jobId: {
        operator: "eq",
        value: jobId,
      },
    },
  };

  const actions = [
    {
      component: () => {
        return <AddWork jobId={jobId} statusId={formik?.values?.statusId} />;
      },
    },
  ];

  const inputs = {
    className: "grid grid-cols-4 gap-16",
    inputs: [
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          {
            label: "Type",
            name: "typeId",
            type: "multi-select",
            options: jobTypes?.map(item => ({
              label: item.description,
              value: item.id,
            })),
            submitOnBlur: true,
          },
          {
            label: "Due Date",
            name: "dueDate",
            type: "date",
            submitOnBlur: true,
            disabled:
              formik?.values?.statusId === 2 || formik?.values?.statusId === 3,
          },
          {
            label: "Due Time",
            name: "duetime",
            type: "time",
            disabled:
              formik?.values?.statusId !== 1 && formik?.values?.statusId !== 5,
            submitOnBlur: true,
          },
        ],
      },
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          { label: "Date", name: "date", type: "date", disabled: true },
          {
            label: "Requested User",
            name: "requestUser.name",
            type: "text",
            disabled: true,
          },
          {
            label: "Requested Group",
            name: "requestGroupId",
            type: "multi-select",
            options: authGroups?.map(item => ({
              label: item.name,
              value: item.id,
            })),
            submitOnBlur: true,
          },
          {
            label: "Pickup Group",
            name: "pickupId",
            type: "multi-select",
            options:
              pickupGroups?.edges?.map(item => ({
                label: item?.name,
                value: item?.id,
              })) || [],
            submitOnBlur: true,
          },
        ],
      },
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          {
            label: "Status",
            name: "statusId",
            type: "multi-select",
            options: jobStatuses?.map(item => {
              return {
                label: item.description,
                value: item.id,
                isDisabled: item.id === 2,
              };
            }),
            submitOnBlur: true,
            disabled: formik?.values?.statusId === 3,
          },
          {
            label: "Confirmed By",
            name: "userId",
            type: "multi-select",
            options:
              entities?.edges?.map(item => ({
                label: item?.name,
                value: item?.id,
              })) || [],
            submitOnBlur: true,
          },
          {
            label: "Viewing Room",
            name: "viewing",
            type: "multi-select",
            options: viewingRooms?.map(item => ({
              label: item?.description,
              value: item?.id,
            })),
            submitOnBlur: true,
          },
        ],
      },
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          {
            label: "Consignment / Loan",
            name: "consignmentId",
            type: "consignmentSearch",
            submitOnBlur: true,
          },
          {
            label: "Issue #",
            name: "issueId",
            type: "multi-select",
            options:
              technicals?.edges?.map(item => ({
                label: item?.id,
                value: item?.id,
              })) || [],
            submitOnBlur: true,
          },
        ],
      },
    ],
  };

  const inputProps = {
    formik,
    ...inputs,
  };

  return (
    <div className="w-full">
      <SectionHeader title={`Job Number ${jobId || ""}`} actions={actions} />
      <div className="mb-8 border-b border-black px-8 pb-12">
        <GridInputs {...inputProps} />
      </div>
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};
export default Detail;
