import { useNavigate } from "react-router-dom";
import { Breadcrumb, MenuButton } from "../../../components/ui";
import CreateContactOffers from "./Actions/CreateContactOffers";
import ExportActiveReport from "../../../components/Modals/ExportActiveReport";
import { useState } from "react";

const PrimaryNav = props => {
  const { formik } = props || {};
  const navigate = useNavigate();

  // find current submodule route
  // const path = pathnames[pathnames?.length - 1];
  // const route = routes?.find((item) => item?.href?.includes(path));
  const queryParams = new URLSearchParams(window.location.search);
  const status = queryParams.get("status") || "all";
  const selected = Object.keys(formik?.values?.selected?.ids || {});
  const listId = +(queryParams.get("listId") || status === "selectListWorking"
    ? queryParams.get("workingListId")
    : null);
  const ids = selected?.map(key => {
    const obj = formik?.values?.selected?.ids?.[key];
    return obj?.item?.id || obj?.id;
  });
  const select = formik?.values?.selected?.select;
  const disabled = ids?.length === 0;

  const offerObj = {
    artwork: {},
    amount: {},
    contact: {},
    currency: {},
    hold: {},
    status: {},
  };

  const statusMap = {
    all: "All Offers",
    selectListWorking: "Select List (Working)",
  };
  const label = statusMap[status] || "All Offers";

  // prepare steps for breadcrumbs
  const steps = [{ label, onClick: () => navigate(`/offer?status=${status}`) }];

  const breadcrumbProps = {
    currentStepIndex: steps?.length - 1,
    onChange: index => steps[index]?.onClick(),
    steps: steps?.map(item => item?.label),
  };

  const defaultActionProps = {
    closeOnBackdrop: false,
    hideCloseButton: true,
    ids,
    listId,
    onClose: () => {
      formik?.resetForm();
    },
    scale: "md",
    select,
  };

  const [isExportReportOpen, setIsExportReportOpen] = useState(false);

  const actionMenuProps = {
    label: "Actions",
    options: [
      {
        disabled,
        label: "Create Contact Offers",
        modalProps: {
          body: CreateContactOffers,
          closeOnBackdrop: false,
          hideCloseButton: true,
          scale: "md",
          ...defaultActionProps,
        },
      },
      {
        disabled: disabled,
        label: "Export Offer Report",
        modalProps: {
          ...defaultActionProps,
          body: ExportActiveReport,
          obj: offerObj,
          type: "Offer",
          ids: ids,
          isExportReportOpen,
          onOpen: () => {
            setIsExportReportOpen(true);
          },
          onClose: () => {
            setIsExportReportOpen(false);
          },
        },
      },
    ],
  };

  return (
    <div className="flex items-center justify-between border-b px-8 py-5">
      <Breadcrumb {...breadcrumbProps} />
      <MenuButton {...actionMenuProps} />
    </div>
  );
};

export default PrimaryNav;
