import { useMutation, useQuery } from "@apollo/client";
import { Route, Routes as RouterRoutes, useParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import { useFormik } from "formik";

import { FETCH_CONTACT as fetchContact } from "../../../../graphql/query/Contact";
import { UPDATE_CONTACT as updateContactMutation } from "../../../../graphql/mutation/Contact";
import AuthRoute from "../../../../components/AuthRoute";
import DetailBar from "./components/DetailBar";
import ActivityTracker from "./components/ActivityTracker";
import routes from "./routes";
import { NavBar } from "../../../../components/ui";
import { Portal, getChangedFields } from "../../../../util";

const Body = props => {
  return (
    <RouterRoutes>
      {routes
        ?.filter?.(item => item?.element)
        ?.map((item, index) => {
          return (
            <Route
              key={index}
              path={item?.href}
              element={
                <AuthRoute permissions={item.permissions}>
                  <item.element {...props} />
                </AuthRoute>
              }
            />
          );
        })}
    </RouterRoutes>
  );
};

const Wizard = props => {
  const { id } = useParams();
  const { data: { contact } = {} } = useQuery(
    fetchContact({
      artistIDs: true,
      contactAddresses: { country: {} },
      contactArtists: { artist: {} },
      contactCompany: {},
      contactFocuses: { focus: {} },
      contactMailingLists: { mailingList: {} },
      contactNumbers: { type: {} },
      country: {},
      documents: {},
      exhUser: {},
      lastOffer: {},
      salesUser: {},
      salesUser2: {},
      type: {},
      type2: {},
    }),
    {
      variables: { id: +id },
    },
  );

  const activityTrackerProps = {
    contact,
  };

  const [updateContact, { loading }] = useMutation(
    updateContactMutation({
      contactAddresses: {},
      contactArtists: { artist: true },
      contactCompany: {},
      contactFocuses: { focus: true },
      contactMailingLists: { mailingList: {} },
      contactNumbers: { type: {} },
      country: {},
      exhUser: {},
      salesUser: {},
      salesUser2: {},
      type: {},
      type2: {},
    }),
  );

  const abortController = useRef();

  const formik = useFormik({
    initialValues: contact ? contact : [],
    enableReinitialize: true,
    onSubmit: values => {
      updateContactValue(values);
    },
  });

  const updateContactValue = values => {
    const changedFields = getChangedFields(values, contact);
    const filteredValues = Object.keys(changedFields).reduce((acc, key) => {
      if (!["contactArtists", "contactFocuses"].includes(key)) {
        acc[key] = changedFields[key];
      }
      return acc;
    }, {});

    if (Object.keys(filteredValues).length > 0) {
      const controller = new AbortController();
      abortController.current = controller;

      updateContact({
        variables: {
          input: {
            id: +id,
            ...filteredValues,
          },
        },
        context: {
          fetchOptions: {
            signal: controller.signal,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (formik?.values && abortController?.current) {
      abortController.current.abort();
    }
  }, [formik?.values]);

  const contactArtists =
    contact?.contactArtists?.map(item => ({
      label: item?.artist?.name,
      value: item?.artist?.id,
      contactArtistId: item?.id,
    })) || [];
  const contactFocuses =
    contact?.contactFocuses?.map(item => ({
      label: item?.focus?.description,
      value: item?.focus?.id,
      contactFocusId: item?.id,
    })) || [];
  useEffect(() => {
    formik.resetForm({
      values: {
        ...contact,
        contactArtists,
        contactFocuses,
      },
    });
  }, [contact]);

  const bodyProps = {
    ...props,
    contact,
    contactId: +id,
    formik,
    loading,
    updateContact,
    updateContactValue,
  };

  return (
    <div className="relative flex min-h-full">
      <Portal id="sidebar">
        <NavBar
          navigation={routes?.filter?.(item => item?.icon)}
          open={props?.open}
        />
      </Portal>
      <DetailBar {...bodyProps} />
      <div className="m-8 mr-[80px] flex flex-1 overflow-x-auto">
        {contact && <Body {...bodyProps} />}
      </div>
      <ActivityTracker {...activityTrackerProps} />
    </div>
  );
};

export default Wizard;
