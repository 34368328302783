import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useRef } from "react";
import { Route, Routes as RouterRoutes, useParams } from "react-router-dom";
import { useFormik } from "formik";
import routes from "./routes";
import { getChangedFields, Portal } from "../../../../util";
import AuthRoute from "../../../../components/AuthRoute";
import { NavBar, Spinner } from "../../../../components/ui";
import { FETCH_ARTWORK as fetchArtwork } from "../../../../graphql/query/Artwork";
import { UPDATE_ARTWORK as updateArtworkMutation } from "../../../../graphql/mutation/Artwork";

const Body = props => {
  const { loading, ...remainingProps } = props;

  return loading ? (
    <div className="h-100% flex w-full items-center justify-center">
      <Spinner />
    </div>
  ) : (
    <RouterRoutes>
      {routes
        ?.filter?.(item => item?.element)
        ?.map((item, index) => {
          return (
            <Route
              key={index}
              path={item?.href}
              element={
                <AuthRoute permissions={item.permissions}>
                  <item.element {...remainingProps} />
                </AuthRoute>
              }
            />
          );
        })}
    </RouterRoutes>
  );
};

const Wizard = props => {
  const abortController = useRef();
  const { id } = useParams();

  const { data: { artwork } = {}, loading } = useQuery(
    fetchArtwork({
      artists: true,
      images: { imgT: {}, imgX: {}, photoMaterial: {} },
      editions: { availableNumbers: {} },
      imagesSummary: { imgT: {}, imgX: {}, photoMaterial: {} },
    }),
    {
      variables: { id: +id },
    },
  );
  const [updateArtwork] = useMutation(updateArtworkMutation());

  const formik = useFormik({
    initialValues: artwork,
    enableReinitialize: true,
    onSubmit: values => {
      const changedFields = getChangedFields(values, artwork);

      if (Object.keys(changedFields).length > 0) {
        const controller = new AbortController();
        abortController.current = controller;

        updateArtwork({
          variables: {
            input: {
              id: +id,
              ...changedFields,
            },
          },
          context: {
            fetchOptions: {
              signal: controller.signal,
            },
          },
        });
      }
    },
  });

  useEffect(() => {
    if (formik?.values && abortController?.current) {
      abortController.current.abort();
    }
  }, [formik?.values]);

  const bodyProps = {
    ...props,
    artwork,
    artworkId: +id,
    formik,
  };

  return (
    <div className="relative flex min-h-full">
      <Portal id="sidebar">
        <NavBar
          navigation={routes?.filter?.(item => item?.icon)}
          open={props?.open}
        />
      </Portal>
      <div className="flex flex-1 overflow-x-auto">
        <Body {...bodyProps} loading={loading} />
      </div>
    </div>
  );
};

export default Wizard;
