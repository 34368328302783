import { useMutation, useQuery } from "@apollo/client";
import { Route, Routes as RouterRoutes, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";

import AuthRoute from "../../../../components/AuthRoute";
import routes from "./routes";

import { NavBar, Spinner } from "../../../../components/ui";
import { FETCH_WAREHOUSE_ITEM as fetchWarehouseItem } from "../../../../graphql/query/WarehouseItem";
import { UPDATE_WAREHOUSE_ITEM as updateWarehouseItemMutation } from "../../../../graphql/mutation/WarehouseItem";
import { Portal, getChangedFields } from "../../../../util";

const Body = props => {
  const { loading, ...remainingProps } = props;

  return loading ? (
    <div className="h-100% flex w-full items-center justify-center">
      <Spinner />
    </div>
  ) : (
    <RouterRoutes>
      {routes
        ?.filter?.(item => item?.element)
        ?.map((item, index) => {
          return (
            <Route
              key={index}
              path={item?.href}
              element={
                <AuthRoute permissions={item.permissions}>
                  <item.element {...remainingProps} />
                </AuthRoute>
              }
            />
          );
        })}
    </RouterRoutes>
  );
};

const Wizard = props => {
  const { id } = useParams();
  const abortController = useRef();
  const [calculatedDimension, setCalculatedDimension] = useState({
    depthIn: 0,
    heightIn: 0,
    widthIn: 0,
    area: 0,
    volume: 0,
  });

  const { data: { warehouseItem } = {}, loading } = useQuery(
    fetchWarehouseItem({
      itemSite: {
        location: {},
      },
      instance: { isBond: {} },
    }),
    {
      variables: { id: +id },
    },
  );

  const [
    updateWarehouseItem,
    {
      data: { updateWarehouseItem: { error: updateError } = {} } = {},
      loading: updateLoading,
    },
  ] = useMutation(updateWarehouseItemMutation());

  const [itemIsBonded, setItemIsBonded] = useState(false);
  const [bondedWarningCount, setBondedWarningCount] = useState(0);

  const formik = useFormik({
    initialValues: warehouseItem,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: Yup.object({
      dimd: Yup.number().positive().nullable(),
      dimh: Yup.number().positive().nullable(),
      dimw: Yup.number().positive().nullable(),
      weight: Yup.number().positive().nullable(),
    }),
    onSubmit: values => {
      const changedFields = getChangedFields(values, warehouseItem);
      if (Object.keys(changedFields).length > 0) {
        const controller = new AbortController();
        abortController.current = controller;
        updateWarehouseItem({
          variables: {
            input: {
              id: +id,
              ...changedFields,
            },
          },
          context: {
            fetchOptions: {
              signal: controller.signal,
            },
          },
        });
      }
    },
  });

  const fineArtInstanceIsBonded = (categoryId, instance) => {
    if (!categoryId || !instance) setItemIsBonded(false);
    if (categoryId === 1 && instance?.isBond) {
      setItemIsBonded(true);
    }
  };

  const calculateDimensions = values => {
    const { dimd, dimh, dimw } = values;

    setCalculatedDimension({
      depthIn: (dimd / 2.54).toFixed(2),
      heightIn: (dimh / 2.54).toFixed(2),
      widthIn: (dimw / 2.54).toFixed(2),
      area: (((dimd / 100) * dimw) / 100).toFixed(2),
      volume: (((((dimd / 100) * dimw) / 100) * dimh) / 100).toFixed(2),
    });
  };

  useEffect(() => {
    warehouseItem && calculateDimensions(warehouseItem);
    warehouseItem &&
      fineArtInstanceIsBonded(
        warehouseItem?.categoryId,
        warehouseItem?.instance,
      );
  }, [warehouseItem]);

  useEffect(() => {
    if (formik?.values && abortController.current) {
      abortController.current.abort();
    }
  }, [formik?.values]);

  const bodyProps = {
    ...props,
    calculatedDimension,
    formik,
    loading,
    updateError,
    updateLoading,
    updateWarehouseItem,
    warehouseItem,
    warehouseItemId: +id,
    showBondedWarning: itemIsBonded && bondedWarningCount === 0,
    setItemIsBonded,
    setBondedWarningCount,
  };

  return (
    <div className="relative flex min-h-full">
      <Portal id="sidebar">
        <NavBar
          navigation={routes?.filter?.(item => item?.icon)}
          open={props?.open}
        />
      </Portal>
      <div className="flex flex-1 overflow-x-auto">
        <Body {...bodyProps} />
      </div>
    </div>
  );
};

export default Wizard;
